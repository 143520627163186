/**
 * Creates an object composed of the picked object properties.
 * Analog of https://docs-lodash.com/v4/pick/
 *
 * @param object the source object
 * @param keys properties to pick
 *
 * @example
 * const object = { 'a': 1, 'b': 'foo', 'c': 'bar' };
 * pick(object, ['a', 'c']) // { a: 1, c: 'bar' }
 */
import { isFunction } from '../isFunction'

type KeysPredicate<T> = (value: T[keyof T]) => boolean

function pick<T>(object: T, keys: KeysPredicate<T>): Partial<T>
function pick<T, K extends keyof T>(object: T, keys: K[]): Pick<T, K>
function pick<T extends Record<string, any>, K extends keyof T>(
  object: T,
  keys: K[] | KeysPredicate<T>,
) {
  if (Array.isArray(keys)) {
    const result: any = {}
    keys.forEach((key) => {
      result[key] = object[key]
    })
    return result
  } else if (isFunction(keys)) {
    const result: Partial<T> = {}

    for (const key in object) {
      if (key in object && keys(object[key])) {
        result[key] = object[key]
      }
    }

    return result
  }
}

export { pick }
