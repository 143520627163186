<template>
  <component :is="eventMarketComponent" v-bind="props" />
</template>

<script setup lang="ts">
import type { FilterConfig } from 'markets-store/marketsbook/types'
import type { TMarket } from 'markets-store/types'
import SimpleMarket from './marketsGroups/SimpleMarket.vue'
import TotalMarket from './marketsGroups/TotalMarket.vue'
import HandicapMarket from './marketsGroups/HandicapMarket.vue'
import type { ButtonProps } from '../../types'

interface Props {
  marketsFilter: FilterConfig | null
  markets?: TMarket[]
  buttonProps?: ButtonProps
}

const props = withDefaults(defineProps<Props>(), {
  markets: () => [],
  buttonProps: () => ({}),
})

const eventMarketComponent = computed(() => {
  switch (props.marketsFilter?.type) {
    case 'total':
      return TotalMarket
    case 'handicap':
      return HandicapMarket
    default:
      return SimpleMarket
  }
})
</script>
