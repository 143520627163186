import type {
  FilterConfig,
  MarketsInFilters,
} from 'markets-store/marketsbook/types'
import { groupBy, pick } from '@st/utils'
import type { TMarket } from 'markets-store'

export interface GetMarketsParams {
  marketsFilter: FilterConfig | null
  marketList?: TMarket[]
  line?: keyof MarketsInFilters
}

export function getMarketsFromConfigLine({
  marketsFilter,
  marketList,
  line,
}: GetMarketsParams): TMarket[] | undefined {
  if (!marketsFilter || !marketList) return []

  const lineName = line ?? 'main'
  let lineConfig
  if (lineName === 'all') {
    lineConfig = Object.values(marketsFilter.markets).flat()
  } else {
    lineConfig = marketsFilter.markets?.[lineName] || []
  }

  const groupedMarkets = groupBy(marketList, (item) => item.id)
  const marketsByIds = lineConfig.map((marketId) =>
    groupedMarkets.get(marketId),
  )

  /* 
    выбираем для отрисовки первый доступный маркет 
    или группу маркетов из конфига 
  */
  return marketsByIds.filter((arr) => arr.length)[0]
}

export function getAllPeriodMarketsFromConfigLine({
  marketsFilter,
  marketList,
}: GetMarketsParams): TMarket[][] | undefined {
  if (!marketsFilter || !marketList) return []

  // id маркетов из конфига, кроме основных
  const lineConfig = Object.values(
    pick(marketsFilter.markets, ['period']),
  ).flat()

  const groupedMarkets = groupBy(marketList, (item) => item.id)

  // все маркеты из конфига, доступные к отрисовке
  return lineConfig
    .map((marketId) => groupedMarkets.get(marketId))
    .filter((arr) => arr.length)
}
